import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import BlockContainer from '../BlockContainer';

import css from './BlockMautic.module.css';

const BlockMautic = props => {
  const {
    blockName,
    blockId,
    className,
    rootClassName,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const mauticID = blockName.split('-')[1];

  const onIframeLoad = (el) => {
    if (el) {
      if (!el.querySelector('script')) {
        const script = document.createElement('script');
        script.type  = 'text/javascript';
        script.src   = `//pigeonletter.com/form/generate.js?id=${mauticID}`;
        el.appendChild(script);
      }
    }
  };

  return (
    <BlockContainer id={blockId} className={classes}>
      <div ref={onIframeLoad} />
    </BlockContainer>
  );
};

BlockMautic.MauticProps = {
  className: null,
  rootClassName: null,
};

BlockMautic.propTypes = {
  blockName: string.isRequired,
  blockId: string,
  className: string,
  rootClassName: string,
};

export default BlockMautic;
