/**
*  Duplicate SectionColumns/SectionColumns.module.css into SectionMautic/SectionMautic.module.css 
*  and SectionColumns/index.js into SectionMautic/index.js
**/

import React, { useLayoutEffect } from 'react';
import { func, node, object, shape, string } from 'prop-types';

import SectionContainer from '../SectionContainer';
import './SectionMautic.css';

// Section component that's able to show blocks in multiple different columns (defined by "numColumns" prop)
const SectionMautic = props => {
  const {
    sectionId,
    sectionName,
    title: { content },
    className,
    rootClassName,
    appearance,
    options,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  useLayoutEffect(() => {
    /** This section is only needed once per page if manually copying **/
    if (typeof window.MauticSDKLoaded == 'undefined') {
      window.MauticSDKLoaded = true;
      var head = document.getElementsByTagName('head')[0];
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://pigeonletter.com/media/js/mautic-form.js';
      script.onload = function () {
        MauticSDK.onLoad();
      };
      head.appendChild(script);
      window.MauticDomain = 'https://pigeonletter.com';
      window.MauticLang = {
        'submittingMessage': "Please wait..."
      }
    } else if (typeof window.MauticSDK != 'undefined') {
      window.MauticSDK.onLoad();
    }
  }, []);  

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {/* <div dangerouslySetInnerHTML={{ __html: `
      <div id="mauticform_wrapper_${sectionName}" class="mauticform_wrapper">
        <form autocomplete="false" role="form" method="post" action="https://pigeonletter.com/form/submit?formId=${content}" id="mauticform_${sectionName}" data-mautic-form="${sectionName}" enctype="multipart/form-data">
          <div class="mauticform-error" id="mauticform_${sectionName}_error"></div>
          <div class="mauticform-message" id="mauticform_${sectionName}_message"></div>
          <div class="mauticform-innerform">


            <div class="mauticform-page-wrapper mauticform-page-1" data-mautic-form-page="1">

              <div id="mauticform_${sectionName}_f_name" class="mauticform-row mauticform-text mauticform-field-1">
                <label id="mauticform_label_${sectionName}_f_name" for="mauticform_input_${sectionName}_f_name" class="mauticform-label">Name</label>
                <input id="mauticform_input_${sectionName}_f_name" name="mauticform[f_name]" value="" placeholder="Your name" class="mauticform-input" type="text" />
                <span class="mauticform-errormsg" style="display: none;"></span>
              </div>

              <div id="mauticform_${sectionName}_email" class="mauticform-row mauticform-email mauticform-field-2">
                <label id="mauticform_label_${sectionName}_email" for="mauticform_input_${sectionName}_email" class="mauticform-label">Email</label>
                <input id="mauticform_input_${sectionName}_email" name="mauticform[email]" value="" placeholder="Your email" class="mauticform-input" type="email" />
                <span class="mauticform-errormsg" style="display: none;"></span>
              </div>

              <div id="mauticform_${sectionName}_f_message" class="mauticform-row mauticform-text mauticform-field-3">
                <label id="mauticform_label_${sectionName}_f_message" for="mauticform_input_${sectionName}_f_message" class="mauticform-label">Message</label>
                <textarea id="mauticform_input_${sectionName}_f_message" name="mauticform[f_message]" class="mauticform-textarea"></textarea>
                <span class="mauticform-errormsg" style="display: none;"></span>
              </div>

              <div id="mauticform_${sectionName}_submit" class="mauticform-row mauticform-button-wrapper mauticform-field-4">
                <button type="submit" name="mauticform[submit]" id="mauticform_input_${sectionName}_submit" value="" class="mauticform-button btn btn-default">Submit</button>
              </div>
            </div>
          </div>

          <input type="hidden" name="mauticform[formId]" id="mauticform_${sectionName}_id" value="${content}" />
          <input type="hidden" name="mauticform[return]" id="mauticform_${sectionName}_return" value="" />
          <input type="hidden" name="mauticform[formName]" id="mauticform_${sectionName}_name" value="${sectionName}" />

        </form>
      </div>
      `}} /> */}

      <div id={`mauticform_wrapper_${sectionName}`} className="mauticform_wrapper">
        <form autoComplete="false" role="form" method="post" action={`https://pigeonletter.com/form/submit?formId=${content}`} id={`mauticform_${sectionName}`} data-mautic-form={`${sectionName}`} encType="multipart/form-data">
          <div className="mauticform-error" id={`mauticform_${sectionName}_error`}></div>
          <div className="mauticform-message" id={`mauticform_${sectionName}_message`}></div>
          <div className="mauticform-innerform">


            <div className="mauticform-page-wrapper mauticform-page-1" data-mautic-form-page="1">

              <div id={`mauticform_${sectionName}_f_name`} className="mauticform-row mauticform-text mauticform-field-1">
                <label id={`mauticform_label_${sectionName}_f_name`} htmlFor={`mauticform_input_${sectionName}_f_name`} className="mauticform-label">Name</label>
                <input id={`mauticform_input_${sectionName}_f_name`} name="mauticform[f_name]" defaultValue="" placeholder="Your name" className="mauticform-input" type="text" />
                <span className="mauticform-errormsg" style={{"display": "none"}}></span>
              </div>

              <div id={`mauticform_${sectionName}_email`} className="mauticform-row mauticform-email mauticform-field-2">
                <label id={`mauticform_label_${sectionName}_email`} htmlFor={`mauticform_input_${sectionName}_email`} className="mauticform-label">Email</label>
                <input id={`mauticform_input_${sectionName}_email`} name="mauticform[email]" defaultValue="" placeholder="Your email" className="mauticform-input" type="email" />
                <span className="mauticform-errormsg" style={{"display": "none"}}></span>
              </div>

              <div id={`mauticform_${sectionName}_f_message`} className="mauticform-row mauticform-text mauticform-field-3">
                <label id={`mauticform_label_${sectionName}_f_message`} htmlFor={`mauticform_input_${sectionName}_f_message`} className="mauticform-label">Message</label>
                <textarea id={`mauticform_input_${sectionName}_f_message`} name="mauticform[f_message]" className="mauticform-textarea"></textarea>
                <span className="mauticform-errormsg" style={{"display": "none"}}></span>
              </div>

              <div id={`mauticform_${sectionName}_submit`} className="mauticform-row mauticform-button-wrapper mauticform-field-4">
                <button type="submit" name="mauticform[submit]" id={`mauticform_input_${sectionName}_submit`} defaultValue="" className="mauticform-button btn btn-default">Submit</button>
              </div>
            </div>
          </div>

          <input type="hidden" name="mauticform[formId]" id={`mauticform_${sectionName}_id`} defaultValue={`${content}`} />
          <input type="hidden" name="mauticform[return]" id={`mauticform_${sectionName}_return`} defaultValue="" />
          <input type="hidden" name="mauticform[formName]" id={`mauticform_${sectionName}_name`} defaultValue={`${sectionName}`} />

        </form>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionMautic.defaultProps = {
  className: null,
  rootClassName: null,
  title: null,
  appearance: null,
  options: null,
};

SectionMautic.propTypes = {
  sectionId: string.isRequired,
  sectionName: string.isRequired,
  className: string,
  rootClassName: string,
  title: object,
  appearance: object,
  options: propTypeOption,
};

export default SectionMautic;